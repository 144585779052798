import * as React from 'react';
import type { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import * as SnackBar from 'notistack';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import createEmotionCache from '../utility/createEmotionCache';
import lightThemeOptions from '../styles/theme/lightThemeOptions';
// import "../styles/globals.css";
import '../public/css/main.css';

import ThemeProvider from '../styles/theme/ThemeProvider';
import Script from 'next/script';
import { useRouter } from 'next/router';
import * as gtag from '../utility/gtag';
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

const lightTheme = createTheme(lightThemeOptions);

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-BW0R2Y451G`}
      ></Script>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-BW0R2Y451G', {
          page_path: window.location.pathname,
          });
          `,
        }}
      />

      <CacheProvider value={emotionCache}>
        <ThemeProvider>
          <SnackBar.SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <CssBaseline />
            <Component {...pageProps} />
          </SnackBar.SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default MyApp;
