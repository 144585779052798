import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';

export const ThemeContext = React.createContext(
  (themeName: string): void => {}

);

interface MyProp {
    children: React.ReactNode;
}
const ThemeProviderWrapper: React.FC<MyProp> = (props) => {
  const curThemeName = 'GreyGooseTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    _setThemeName(themeName);
  };

  return (
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
